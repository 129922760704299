import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import CounterPayd from "../elements/counters/CounterPayd"
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import CreateSite from "../elements/contact/CreateSite";

import Helmet from "../component/common/Helmet";
import TabThree from "../elements/tab/TabThree";

import logoLight from "../assets/images/logo/logo-light.png";
import logoAllDark from "../assets/images/logo/logo-all-dark.png";
import SobreImg from "../assets/images/bg/imagem-1.jpg";
import './CreativeLanding.css'
const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Creative ",
    description:
      "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

const list = [
  {
    image: "image-1",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];


class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }


  componentDidMount() {
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidMount() {
    // this.handleClickOutsideDiv2 = this.handleClickOutsideDiv2.bind(this);
    // document.addEventListener('click', this.handleClickOutsideDiv2);
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.handleClickOutsideDiv2);
  }

  handleClickOutsideDiv2(event) {
    // if (event.target.closest("#div1")) {
    //   if (!event.target.closest("#div2")) {
    //     console.log("Clique na div1 e fora da div2!");
    //     this.CLoseMenuTrigger()
    //     // Aqui você pode executar qualquer ação desejada
    //   }
    // }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");

  }
  CLoseMenuTrigger() {

    document.querySelector(".header-wrapper").classList.remove("menu-open");

  }
  stickyHeader() { }
  render() {
    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Creative Landing" />
        <div id="div1">

          {/* Start Header Area  */}
          <div id="div2">


            <header className="header-area formobile-menu header--fixed default-color">
              <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">
                      <img className="logo-1" src={logoLight} alt="imobil site para imobiliária e imobil site para corretores" width="200px" />
                      <img className="logo-2" src={logoAllDark} alt="imobil site para imobiliária e imobil site para corretores" />
                    </a>
                  </div>
                </div>
                <div className="header-right">
                  <nav className="mainmenunav d-lg-block">
                    <Scrollspy
                      className="mainmenu"
                      items={[
                        "home",
                        "criarsite",
                        "Sobre",
                        "portfolio",
                        "precos",
                      ]}
                      currentClassName="is-current"
                      offset={-200}
                    >
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#home">Home</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#criarsite">Criar Site</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#Sobre">Sobre</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#portfolio">Customização</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="#precos">Preço</a>
                      </li>
                      <li onClick={this.CLoseMenuTrigger}>
                        <a href="https://painel.imobilsitesimobiliarios.com.br/#/login">Painel</a>
                      </li>
                      {/* <li>
                    <a href="#contact">Contato</a>
                  </li> */}
                    </Scrollspy>
                  </nav>
                  <div className="header-btn">
                    <a
                      className="rn-btn"
                      href="https://painel.imobilsitesimobiliarios.com.br/#/login"
                    >
                      <span>Painel</span>
                    </a>
                  </div>
                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </header>
          </div>
          {/* End Header Area  */}

          {/* Start Slider Area   */}
          <div className="slider-activation slider-creative-agency" id="home">
            <div className="c elemento-pai" data-black-overlay="4">
              {SlideList.map((value, index) => (
                <div
                  className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title theme-gradient">
                              iMobil
                            </h1>

                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p style={{ fontSize: '25px' }} className="description"><strong>Site para Imobiliária</strong> ou <strong>Site para corretores</strong> por apenas R$ 249,90</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href="#criarsite"
                              >
                                Crie seu Site Imobiliário
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End Slider Area   */}

          <div className="rn-contact-us ptb--120 bg_color--1" id="criarsite">
            <CreateSite />
          </div>

          {/* Start Sobre Area */}
          <div className="Sobre-area ptb--120 bg_color--5" id="Sobre">
            <div className="Sobre-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5" style={{ marginBottom: '20px' }}>
                    <div className="thumbnail">
                      <img className="w-100" src={SobreImg} alt="imobil site para imobiliária e imobil site para corretores" style={{ borderRadius: '10px' }} />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="Sobre-inner inner">
                      <div className="section-title">
                        <h3 className="title">Sites para Imobiliárias e</h3>
                        <h3 className="title">Sites para Corretores</h3>
                        <br></br>
                        <p className="description">
                          Com apenas um clique você cria seu site imobiliário de forma totalmente gratuíta, na hora,  {" "}
                          <a href="javascript:void(0)">personalizável</a>. Sem reuniões e sem desenvolvedor.

                        </p>
                      </div>
                      <div className="row mt--30">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{ marginBottom: '20px' }}>
                          <div className="Sobre-us-list">
                            <h3 className="title">Crie o site para sua imobiliária na hora e de graça</h3>
                            <p>
                              Seu site imobiliário é criado em menos de um minuto.
                              Você ganha o primeiro grátis para testar o serviço. Não precisa fornecer o cartão de crédito e se não gostar não precisa pagar.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="Sobre-us-list">
                            <h3 className="title">Customize o site para sua imobiliária</h3>
                            <p>
                              Acreditamos em tecnologia, transparência e praticidade! Você mesmo customiza o site da sua imobiliária e deixa ela com a cara da sua empresa. Sem fidelização e sem taxa de cancelamento.
                            </p>
                          </div>
                        </div>
                        <br></br>


                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
          {/* End Sobre Area */}

          {/* Start Portfolio Area */}
          <div
            className="portfolio-area pt--120 pb--140 bg_color--1"
            id="portfolio"
          >
            <div className="rn-slick-dot">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                      <h2 className="title">Customize você mesmo o site da sua imobiliária</h2>
                      <br></br>
                      <p>
                        Você customiza o site imobiliário para ter a cara da sua empresa. Você mesmo pode alterar os textos, cores e imagens do site da forma que você preferir. Sem precisar de desenvolvedor.
                      </p>
                    </div>
                  </div>


                </div>

                {/* <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider {...slickDot}>
                      {list.map((value, index) => (
                        <div className="portfolio" key={index}>
                          <div className="thumbnail-inner">
                            <div className={`thumbnail ${value.image}`}></div>
                            <div
                              className={`bg-blr-image ${value.image}`}
                            ></div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p>{value.category}</p>
                              <h4>
                                <a href="/portfolio-details">{value.title}</a>
                              </h4>
                              <div className="portfolio-button">
                                <a className="rn-btn" href="/portfolio-details">
                                  Case Study
                                </a>
                              </div>
                            </div>
                          </div>
                          <Link
                            className="link-overlay"
                            to="/portfolio-details"
                          ></Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div> */}

              </div>
            </div>
            <div className="pad-9 designer-portfolio-area bg_color--1">
              <div className="plr_sm--30 plr_md--30">
                <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
              </div>
            </div>
          </div>
          {/* End Portfolio Area */}
          <div id="precos">
            <div className="Sobre-area ptb--120 bg_color--1" id="Sobre">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight900">Site para Imobiliária e Corretores por apenas <strong style={{ color: 'green' }}>R$ 249,90 </strong>mensais</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* Start CounterUp Area */}
            <div className="rn-counterup-area  p pb--110 bg_color--5">
              <div className="container">

                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                      <h3 className="fontWeight500">Até onde posso ir de graça com a <strong>IMOBIL</strong> Sites para Imobiliárias e Corretores?</h3>
                    </div>
                  </div>
                </div>
                <CounterOne />
              </div>
            </div>
            <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                      <h3 className="fontWeight500">Até onde posso ir por <br></br> <br></br><strong> R$ 249,90</strong> com a  <strong>IMOBIL</strong> Sites para Imobiliárias e Corretores?</h3>
                    </div>
                  </div>
                </div>
                <CounterPayd />
              </div>
            </div>
          </div>



          <FooterTwo />

          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeLanding;
