
import React, { useState, useEffect, useRef } from 'react';
import './Modal.css';
import lottie from 'lottie-web';
import gif from './carregando-criacao-site.gif';
import teamWork from './teamwork-web-design.json';
const TypingEffect2 = ({ text }) => {
    const [displayText, setDisplayText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const delay = 25; // Delay entre cada letra (em milissegundos)

        const intervalId = setInterval(() => {
            if (currentIndex < text.length) {
                setDisplayText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            } else {
                clearInterval(intervalId);
            }
        }, delay);

        return () => clearInterval(intervalId);
    }, [text, currentIndex]);

    return <h3 className="titulo-view-test">{displayText}</h3>;
};
const TypingEffect1 = ({ text }) => {
    const [displayText, setDisplayText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const delay = 25; // Delay entre cada letra (em milissegundos)

        const intervalId = setInterval(() => {
            if (currentIndex < text.length) {
                setDisplayText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            } else {
                clearInterval(intervalId);
            }
        }, delay);

        return () => clearInterval(intervalId);
    }, [text, currentIndex]);

    return <h3 className="titulo-view-test">{displayText}</h3>;
};

const Modal3 = ({ isOpen, onClose, children }) => {
    const [changeAnimation, setChangeAnimation] = useState(false);
    const containerLottie = useRef(null);
    const [changeAnimationIteration, setChangeAnimationIteration] = useState(0)

    useEffect(() => {
        let anim;
        import('./teamwork-video-conference.json').then(animationData => {
            anim = lottie.loadAnimation({
                container: containerLottie.current,
                renderer: 'svg', // ou 'canvas', 'html'
                loop: true,
                autoplay: true,
                animationData: animationData.default, // Use .default para acessar o valor padrão do módulo
            });
            containerLottie.current.style.height = '80vh';
        });
        return () => {
            if (anim) {
                anim.destroy(); // Limpa a animação quando o componente é desmontado
            }
        };



    }, []);


    if (!isOpen) return null;

    return (
        <div>
            <div className="conteudo">
                <div ref={containerLottie}></div>
            </div>
            <TypingEffect1 text="Preparando melhores cores e imagens para sua empresa se destacar..." />
        </div>
    );
};

export default Modal3;

