import React, { Component } from "react";
import CreateSiteForm from "./CreateSiteForm";

import about from "../../assets/images/bg/minimalismo-no-escritorio.jpg";

class CreateSite extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Crie o site para sua imobiliária com um clique</h2>
                <p className="description">
                  Informe o nome da sua empresa (sem pontos e acentos) e um email válido. O site estará no ar em 1 minuto e você receberá
                  seus acessos pelo email.
                </p>
              </div>
              <div className="form-wrapper">
                <CreateSiteForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={about} alt="imobilsitesimobiliarios" style={{ borderRadius: '10px', marginTop: '20px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateSite;
